<template>
    <v-container class="azul-sus">
        <v-card class="ma-auto custom-card" max-width="600">
            <v-card-title class="ma-auto">
                <v-row>
                    <v-col class="text-center">
                        Menu
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-auto">
                <v-row dense class="ma-auto">
                    <v-col class="custon-btn text-center"  dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                       <!-- <LerQrcode/>-->
                    </v-col>
                    <v-col v-if="paciente_lefo.id" dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <v-card outlined class="text-center" >
                            <v-card-text class="custom-btn">
                                {{paciente_lefo.id}} - {{paciente_lefo.nome}} / {{paciente_lefo.dt_nascimento}} / {{paciente_lefo.cpf}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <Sus :current-item="paciente" @atualizaPlus="atualiza_boolean()"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <ListaExameFundoOlho :paciente="paciente_lefo"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <ListaBiomarcador :paciente="paciente_lefo" :atualiza="trgAtualiza"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <ListaRegular :paciente="paciente_lefo"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <Lista :paciente="paciente_lefo"/>
                    </v-col>
                    <v-col dense :sx="12" :ms="12" :md="12" :lg="12" :cols="12">
                        <v-btn outlined color="#fff">
                            <a href="/dashboard-sus">Dashboard</a>
                        </v-btn> 
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>   
    </v-container>
</template>
<script>
    import api from '@/http'
    //import LerQrcode from './LerQrcode.vue'
    export default{
        name:'Menu',
        components:{
            Sus: () => import("./Sus.vue"),
            Lista: () => import("./Lista.vue"),
            ListaRegular: () => import("./ListaRegular.vue"),
            ListaBiomarcador: () => import("./ListaBiomarcador.vue"),
            ListaExameFundoOlho: () => import("./ListaExameFundoOlho.vue"),
        //    LerQrcode
        },
        data:()=>({
            paciente:{id:null},
            paciente_lefo:{},
            trgAtualiza:false,
            loading:false,
            glicemias:[],
            examesfdos:[],
            regulacoes:[],
            pacientes:[],
        }),
        methods:{
            get_listas(url, objeto){
                this.loading = true
                api.get(url)
                .then(res=>{
                    if (Array.isArray(res.data)) {
                        this[objeto]=res.data[0]
                    }else{
                        this[objeto]=res.data
                    }
                })
                .finally(()=>{this.loading = false})
            },

            atualiza_boolean(val){
                this.trgAtualiza = val
            }
        },
        mounted(){
            if(this.$route.params.id !==undefined){
                this.get_listas(`mutirao-sus/pacientes/?etapa=paciente&id=${this.$route.params.id}`, 'paciente_lst')
            }
        }
        /*
        wacth:{
            trgAtualiza(newVal){
                if(newVal){
                    this.get_listas('mutirao-sus/pacientes/?etapa=biomarcador', 'glicemias')
                    this.get_listas('mutirao-sus/pacientes/?etapa=exame-fundo-olho', 'examesfdos')
                    this.get_listas('mutirao-sus/pacientes/?etapa=regular', 'regulacoes')
                    this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
                }
            }
        },

        */
    }
</script>
<style>
.azul-sus{
    background-color: #305f67;
    height: 100%;
}
.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>