<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" max-width="600" style="overflow-y: auto;"  fullscreen persistent>
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-if="currentItem.id===null" 
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                block
                class="custom-btn"
            >   REGULAR
                <v-icon small class="ma-3" color="white">mdi-plus-outline</v-icon>
            </v-btn>
            
            <v-btn
                v-else 
                v-bind="attrs"
                v-on="on"
                icon
                class="custom-btn"
            > 
                <v-icon small class="ma-3" color="white">mdi-book-edit-outline</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-4 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                REGULAR
            </v-card-title>
            <v-card-text>
                <div id="imprimir">
                    <v-card class="pa-2 ma-0 mt-4">
                        <v-row dense>
                            <v-col dense :cols="12">
                                Cod atd: {{ currentItem.id }}
                            </v-col>
                            <v-col dense :cols="12">
                                <span class="text-caption">NOME:</span> {{ currentItem.nome }}
                            </v-col>
                            <v-col dense :cols="12">
                                <span class="text-caption">CONTATO:</span>  {{ currentItem.contato }}
                            </v-col>
                            <v-col dense :cols="12"><span class="text-caption">Data Nasc:</span> {{ new Date(currentItem.dt_nascimento+' 00:00:00').toLocaleDateString() }}</v-col>
                            <v-col dense :cols="12"><span class="text-caption">IDADE:</span> {{ calcularIdade(currentItem.dt_nascimento) }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-divider class=""></v-divider></v-col>
                        </v-row>
                        <v-row>
                            <v-col dense v-if="false" :cols="12">
                                <span  class="text-caption">Tempo de diabetes:</span>  {{ currentItem.tempo_diabetes }} - anos
                            </v-col>
                            
                            <v-col v-if="currentItem.glicemia_capilar" dense :cols="6"><span class="text-caption">Glicemia Capilar:</span> {{ currentItem.glicemia_capilar }} mg/dl</v-col>
                            <v-col v-if="currentItem.glicemia_capilar" dense :cols="6"><span class="text-caption">Ref: Em jejum: 99 mg/dl <br>após alimentação: até 140 mg/dl</span></v-col>
                            <v-col v-if="currentItem.glicemia_plasmatica" dense :cols="6"><span class="text-caption">Glicemia Plasmática:</span> {{ currentItem.glicemia_plasmatica }} mg/dl</v-col>
                            <v-col v-if="currentItem.glicemia_plasmatica" dense :cols="6"><span class="text-caption">Ref: Em jejum: 70 a 99 mg/dl</span></v-col>
                            <v-col v-if="currentItem.hemoglobina_glicada" dense :cols="6"><span class="text-caption">Hemoglobina Glicada:</span> {{ currentItem.hemoglobina_glicada }}%</v-col>
                            <v-col v-if="currentItem.hemoglobina_glicada" dense :cols="6">
                                <span class="text-caption">
                                    Ref: Em jejum: até 5,7% - Normal <br>de 5,8 a 6,4 - pré-diabético <br> de 6,5% - provável diabético
                                </span>
                            </v-col>
                            
                            <v-col v-if="currentItem.od_sem_alteracao" dense :cols="12"><span class="text-caption">OD s/alteração:</span> <span :class="currentItem.od_sem_alteracao? `corvermelha`:``">{{ sim_nao(currentItem.od_sem_alteracao) }}</span></v-col>
                            <v-col v-if="currentItem.od_rdnp_leve" dense :cols="12"><span class="text-caption">OD rdnp leve:</span> <span :class="currentItem.od_rdnp_leve? `corvermelha`:``">{{ sim_nao(currentItem.od_rdnp_leve) }}</span></v-col>
                            <v-col v-if="currentItem.od_rdnp_moderada" dense :cols="12"><span class="text-caption">OD rdnp moderada:</span> <span :class="currentItem.od_rdnp_moderada? `corvermelha`:``">{{ sim_nao(currentItem.od_rdnp_moderada) }}</span></v-col>
                            <v-col v-if="currentItem.od_rdnp_grave_m_grave" dense :cols="12"><span class="text-caption">OD rdnp grave/ m grave:</span> <span :class="currentItem.od_rdnp_grave_m_grave? `corvermelha`:``">{{ sim_nao(currentItem.od_rdnp_grave_m_grave) }}</span></v-col>
                            <v-col v-if="currentItem.od_rdp" dense :cols="12"><span class="text-caption">OD rdp:</span> <span :class="currentItem.od_rdp? `corvermelha`:``">{{ sim_nao(currentItem.od_rdp) }}</span></v-col>
                            <v-col v-if="currentItem.od_edema_macular" dense :cols="12"><span class="text-caption">OD edema macular:</span> <span :class="currentItem.od_edema_macular? `corvermelha`:``">{{ sim_nao(currentItem.od_edema_macular) }}</span></v-col>
                            <v-col v-if="currentItem.oe_sem_alteracao" dense :cols="12"><span class="text-caption">OE sem alteracao:</span> <span :class="currentItem.oe_sem_alteracao? `corvermelha`:``">{{ sim_nao(currentItem.oe_sem_alteracao) }}</span></v-col>
                            <v-col v-if="currentItem.oe_rdnp_leve" dense :cols="12"><span class="text-caption">OE rdnp leve:</span> <span :class="currentItem.oe_rdnp_leve? `corvermelha`:``">{{ sim_nao(currentItem.oe_rdnp_leve) }}</span></v-col>
                            <v-col v-if="currentItem.oe_rdnp_moderada" dense :cols="12"><span class="text-caption">OE rdnp moderada:</span> <span :class="currentItem.oe_rdnp_moderada? `corvermelha`:``">{{ sim_nao(currentItem.oe_rdnp_moderada) }}</span></v-col>
                            <v-col v-if="currentItem.oe_rdnp_grave_m_grave" dense :cols="12"><span class="text-caption">OE rdnp grave/ m grave:</span> <span :class="currentItem.oe_rdnp_grave_m_grave? `corvermelha`:``">{{ sim_nao(currentItem.oe_rdnp_grave_m_grave) }}</span></v-col>
                            <v-col v-if="currentItem.oe_rdp" dense :cols="12"><span class="text-caption">OE rdp:</span> <span :class="currentItem.oe_rdp? `corvermelha`:``">{{ sim_nao(currentItem.oe_rdp) }}</span></v-col>
                            <v-col v-if="currentItem.oe_edema_macular" dense :cols="12"><span class="text-caption">OE edema_macular:</span> <span :class="currentItem.oe_edema_macular? `corvermelha`:``">{{ sim_nao(currentItem.oe_edema_macular) }}</span></v-col>
                            <v-col v-if="currentItem.catarata" dense :cols="12"><span class="text-caption">catarata:</span> <span :class="currentItem.catarata? `corvermelha`:``">{{ sim_nao(currentItem.catarata) }}</span></v-col>
                            <v-col v-if="currentItem.glaucoma" dense :cols="12"><span class="text-caption">glaucoma:</span> <span :class="currentItem.glaucoma? `corvermelha`:``">{{ sim_nao(currentItem.glaucoma) }}</span></v-col>
                            <v-col v-if="currentItem.outras" dense :cols="12"><span class="text-caption">outras:</span> <span :class="currentItem.outras? `corvermelha`:``">{{ sim_nao(currentItem.outras) }}</span></v-col>
                            <v-col v-if="currentItem.observacoes_doencas_olhos" dense :cols="12"><span class="text-caption">observacoes doencas olhos:</span> <span :class="currentItem.observacoes_doencas_olhos? `corvermelha`:``">{{ currentItem.observacoes_doencas_olhos }}</span></v-col>
                            <v-col v-if="currentItem.observacoes" dense :cols="12"><span class="text-caption">observacoes:</span> <span :class="currentItem.observacoes? `corvermelha`:``">{{ currentItem.observacoes }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-divider class=""></v-divider></v-col>
                        </v-row>
                        <v-row>
                            <v-col dense :cols="12"><span class="text-caption">CONDUTA:</span> 
                               <span class="corvermelha">
                                   {{ currentItem.orientar }}
                               </span> 
                            </v-col>
                        </v-row>
                     
                    </v-card>
                </div>
            </v-card-text>
            <!--
                <v-card-actions>
                    <v-row dense>
                        <v-col dense :cols="12">
                            <v-btn :loading="loading" outlined block @click="post_lista()"><v-icon>mdi-content-save</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                -->
            <v-row min-height="20">
                <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import httpApiService from '@/http'
export default{
    name:'Paciente',
    props:{
        currentItem:{type:Object},
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    data:()=>({
        dialog:false,
        loading:false,
        msg:'',
        sexos:[],
        orgaos_expedidores:[],
        estados:[],
        frequencia_hemoglobina_glicada_diaria:[],
        cidades:[],
        problema_pes:[],
    }),
    methods:{
        imprimir() {
            const element = document.getElementById('imprimir');
            window.print(element);
        },
        calcularIdade(anoNascimento) {
            const anoAtual = new Date().getFullYear();
            return anoAtual - new Date(anoNascimento).getFullYear();
        },
        sim_nao(val) {
            if(val===null || val==='' ){
                return 'N/A'
            }
            return val?'SIM':'NÃO';
        },
        async post_lista(){
            this.loading = true
            if(!this.$refs.form.validate()) {
                this.loading=false 
                return false
            }

            const response = await httpApiService.patch(`mutirao-sus/paciente/${this.currentItem.id}/`,this.currentItem)
            
            if(response.status===200){
                this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                // this.$emit('atualizaRegular')
                this.$emit('fechadialog', true)
            }else{
                for(const i in response.response.data){
                    this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                }    
            }
            this.loading=false
            
        },
    },
    mounted(){
    },
    watch: {
        openModal: {
            immediate: true,
            handler(newVal) {
                this.dialog = newVal; // Sincroniza a abertura do modal
            },
        },
    },
    
}
</script>
<style>

.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
  .corvermelha{
    color: red;
  }
</style>