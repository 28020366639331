<template>
  <div>
    <v-row>
      <v-col justify="center" align="center" class="fill-height">
        <div id="reader" style="width: 300px"></div>
        <p v-if="scannedCode">
          Código Escaneado: <a :href="scannedCode">{{ scannedCode }}</a>
        </p>
        <v-btn @click="resumeScanner" v-if="scannerPaused">Reativar Scanner</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Html5QrcodeScanner } from "html5-qrcode";

export default {
  data() {
    return {
      scannedCode: null,
      qrScanner: null, // Instância do scanner
      scannerPaused: false, // Estado para verificar se o scanner está pausado
    };
  },
  mounted() {
    this.qrScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: 250 },
      false
    );

    this.qrScanner.render(
      (decodedText) => {
        if (this.scannedCode !== decodedText) {
          this.scannedCode = decodedText;

          const idPaciente = decodedText.split("/").pop();
          this.$emit("id_paciente", idPaciente);

          // Pausa o scanner
          this.qrScanner.resume();
          this.scannerPaused = true;
        }
      },
      (errorMessage) => {
        console.warn("Erro ao escanear QR Code:", errorMessage);
      }
    );

  },
  methods: {
    resumeScanner() {
      // Retoma o scanner para próximo uso
      this.qrScanner.resume();
      this.scannerPaused = false;
    },
  },
  beforeDestroy() {
    // Garante que o scanner seja parado ao destruir o componente
    if (this.qrScanner) {
      this.qrScanner.clear();
    }
  },
};
</script>
<style scoped>
.qr-options button,
.qr-options div {
  margin: 12px 0;
  /* Aumenta o espaçamento vertical entre os itens */
  padding: 10px;
  /* Aumenta a área clicável */
  font-size: 26px;
  /* Aumenta o tamanho da fonte para melhor visibilidade */

}
.html5-qrcode-element{
 color: brown;
}
</style>
