<template>
    <v-container>
        <h1>Dashboard</h1>
        <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
            <v-card-title>Faixa etária</v-card-title>
            <v-card-text>
                <MyBar :base="faixaEtaria()" label="Idades"></MyBar>
            </v-card-text>
        </v-card>
        <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
            <v-card-title>Sexo</v-card-title>
            <v-card-text>
                <MyBar :base="sexo()" label="Sexo"></MyBar>
            </v-card-text>
        </v-card>
        <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
            <v-card-title>Orientação</v-card-title>
            <v-card-text>
                <MyBar :base="orientacao()" label="Orientação"></MyBar>
            </v-card-text>
        </v-card>
        <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
            <v-card-title>Glaucoma e Catarata</v-card-title>
            <v-card-text>
                <MyBar :base="glaucomaCatarata()" label="Glaucoma e Catarata"></MyBar>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col cols="12">
                <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
                    <v-card-title>Glaucoma e Catarata</v-card-title>
                    <v-card-text>
                        <MyBar :base="drdp()" label="OD RDP"></MyBar>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card :loading="pacientes.length === 0" class="my-3" elevation="10">
                    <v-card-title>Glaucoma e Catarata</v-card-title>
                    <v-card-text>
                        <MyBar :base="erdp()" label="OE RDP"></MyBar>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </v-container>
</template>
<script>
import api from '@/http'
export default {
    name: 'GraficoSemana',
    components: {
        MyBar: () => import('./MyBar.vue')
    },
    data: () => ({
        pacientes: [],
    }),
    methods: {
        get_listas(url, objeto) {
            this.loading = true
            api.get(url)
                .then(res => {
                    this[objeto] = res.data
                })
                .finally(() => { this.loading = false })
        },
        calcularIdade(anoNascimento) {
            const anoAtual = new Date().getFullYear();
            return anoAtual - new Date(anoNascimento).getFullYear();
        },
        calcularPercentuais(data) {
            const total = data.reduce((sum, valor) => sum + valor, 0); // Soma o total dos valores
            return data.map(valor => ((valor / total) * 100).toFixed(2)); // Calcula os percentuais e formata com 2 casas decimais
        },
        faixaEtaria() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                "até 18 anos": 0,
                "entre 18 e 29 anos": 0,
                "entre 30 e 39 anos": 0,
                "entre 40 e 49 anos": 0,
                "entre 50 e 59 anos": 0,
                "entre 60 e 69 anos": 0,
                "entre 70 e 79 anos": 0,
                "entre 80 e 89 anos": 0,
                "entre 90 e 99 anos": 0
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const idade = this.calcularIdade(paciente.dt_nascimento);
                if (idade <= 18) {
                    contagem["até 18 anos"]++;
                } else if (idade > 18 && idade <= 29) {
                    contagem["entre 18 e 29 anos"]++;
                } else if (idade > 30 && idade <= 39) {
                    contagem["entre 30 e 39 anos"]++;
                } else if (idade > 40 && idade <= 49) {
                    contagem["entre 40 e 49 anos"]++;
                } else if (idade > 50 && idade <= 59) {
                    contagem["entre 50 e 59 anos"]++;
                } else if (idade > 60 && idade <= 69) {
                    contagem["entre 60 e 69 anos"]++;
                } else if (idade > 70 && idade <= 79) {
                    contagem["entre 70 e 79 anos"]++;
                } else if (idade > 80 && idade <= 89) {
                    contagem["entre 80 e 89 anos"]++;
                } else if (idade > 90 && idade <= 99) {
                    contagem["entre 90 e 99 anos"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        sexo() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                'Masculino': 0,
                'Feminino': 0,
                'Intersexo': 0,
                'Não especificado': 0,
                'Outro': 0,
                'Não informado': 0,
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const sexo = paciente.sexo;
                if (sexo === 'F') {
                    contagem["Feminino"]++;
                } else if (sexo === 'M') {
                    contagem["Masculino"]++;
                } else if (sexo === 'I') {
                    contagem["Intersexo"]++;
                } else if (sexo === 'N') {
                    contagem["Não especificado"]++;
                } else if (sexo === 'O') {
                    contagem["outros"]++;
                } else {
                    contagem["Não informado"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        orientacao() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                'Alta': 0,
                'Consulta': 0,
                'CIRURGIA/LASER': 0
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const orientar = paciente.orientar;
                if (orientar === 'ALTA') {
                    contagem["Alta"]++;
                } else if (orientar === 'CONSULTA') {
                    contagem["Consulta"]++;
                } else if (orientar === 'CIRURGIA/LASER') {
                    contagem["CIRURGIA/LASER"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        glaucomaCatarata() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                'Glaucoma': 0,
                'Catarata': 0,
                'Sem este diagnóstico': 0
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const catarata = paciente.catarata;
                const glaucoma = paciente.glaucoma;
                if (catarata === true) {
                    contagem["Glaucoma"]++;
                } else if (glaucoma === true) {
                    contagem["Catarata"]++;
                } else {
                    contagem["Sem este diagnóstico"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        drdp() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                'od_sem_alteracao': 0,
                'od_rdnp_leve': 0,
                'od_rdnp_moderada': 0,
                'od_rdnp_grave_m_grave': 0,
                'od_rdp': 0,
                'od_edema_macular': 0,
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const od_sem_alteracao = paciente.od_sem_alteracao;
                const od_rdnp_leve = paciente.od_rdnp_leve;
                const od_rdnp_moderada = paciente.od_rdnp_moderada;
                const od_rdnp_grave_m_grave = paciente.od_rdnp_grave_m_grave;
                const od_rdp = paciente.od_rdp;
                const od_edema_macular = paciente.od_edema_macular;
                if (od_sem_alteracao === true) {
                    contagem["od_sem_alteracao"]++;
                } else if (od_rdnp_leve === true) {
                    contagem["od_rdnp_leve"]++;
                } else if (od_rdnp_moderada === true) {
                    contagem["od_rdnp_moderada"]++;
                } else if (od_rdnp_grave_m_grave === true) {
                    contagem["od_rdnp_grave_m_grave"]++;
                } else if (od_rdp === true) {
                    contagem["od_rdp"]++;
                } else if (od_edema_macular === true) {
                    contagem["od_edema_macular"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        erdp() {
            // Inicialize os contadores para cada faixa etária
            const contagem = {
                'oe_sem_alteracao': 0,
                'oe_rdnp_leve': 0,
                'oe_rdnp_moderada': 0,
                'oe_rdnp_grave_m_grave': 0,
                'oe_rdp': 0,
                'oe_edema_macular': 0
            };

            // Itere sobre os pacientes e calcule as faixas etárias
            this.pacientes.forEach((paciente) => {
                const oe_sem_alteracao = paciente.oe_sem_alteracao;
                const oe_rdnp_leve = paciente.oe_rdnp_leve;
                const oe_rdnp_moderada = paciente.oe_rdnp_moderada;
                const oe_rdnp_grave_m_grave = paciente.oe_rdnp_grave_m_grave;
                const oe_rdp = paciente.oe_rdp;
                const oe_edema_macular = paciente.oe_edema_macular;
                if (oe_sem_alteracao === true) {
                    contagem["oe_sem_alteracao"]++;
                } else if (oe_rdnp_leve === true) {
                    contagem["oe_rdnp_leve"]++;
                } else if (oe_rdnp_moderada === true) {
                    contagem["oe_rdnp_moderada"]++;
                } else if (oe_rdnp_grave_m_grave === true) {
                    contagem["oe_rdnp_grave_m_grave"]++;
                } else if (oe_rdp === true) {
                    contagem["oe_rdp"]++;
                } else if (oe_edema_macular === true) {
                    contagem["oe_edema_macular"]++;
                }
            });

            // Separe os dados e os labels
            const labels = Object.keys(contagem); // Extrai as chaves (labels)
            const data = Object.values(contagem); // Extrai os valores (quantidades)
            const percentuais = this.calcularPercentuais(data);

            return { labels, data, percentuais };
        },
        sexoPorFaixaEtaria() {
            // Inicialize a estrutura para armazenar as contagens por faixa etária e sexo
            const contagem = {
                "até 18 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 18 e 29 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 30 e 39 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 40 e 49 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 50 e 59 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 60 e 69 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 70 e 79 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 80 e 89 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "entre 90 e 99 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
                "maiores de 100 anos": { Masculino: 0, Feminino: 0, Intersexo: 0, "Não especificado": 0, Outro: 0, "Não informado": 0 },
            };

            // Itere sobre os pacientes
            this.pacientes.forEach((paciente) => {
                const idade = this.calcularIdade(paciente.dt_nascimento);
                const sexo = paciente.sexo;

                // Determine a faixa etária
                let faixaEtaria = "";
                if (idade <= 18) faixaEtaria = "até 18 anos";
                else if (idade <= 29) faixaEtaria = "entre 18 e 29 anos";
                else if (idade <= 39) faixaEtaria = "entre 30 e 39 anos";
                else if (idade <= 49) faixaEtaria = "entre 40 e 49 anos";
                else if (idade <= 59) faixaEtaria = "entre 50 e 59 anos";
                else if (idade <= 69) faixaEtaria = "entre 60 e 69 anos";
                else if (idade <= 79) faixaEtaria = "entre 70 e 79 anos";
                else if (idade <= 89) faixaEtaria = "entre 80 e 89 anos";
                else if (idade <= 99) faixaEtaria = "entre 90 e 99 anos";
                else faixaEtaria = "maiores de 100 anos";

                // Determine o sexo e incremente no objeto correspondente
                if (sexo === "F") contagem[faixaEtaria]["Feminino"]++;
                else if (sexo === "M") contagem[faixaEtaria]["Masculino"]++;
                else if (sexo === "I") contagem[faixaEtaria]["Intersexo"]++;
                else if (sexo === "N") contagem[faixaEtaria]["Não especificado"]++;
                else if (sexo === "O") contagem[faixaEtaria]["Outro"]++;
                else contagem[faixaEtaria]["Não informado"]++;
            });

            // Organize os dados para exibição
            const labels = Object.keys(contagem); // Faixas etárias
            const data = labels.map((faixa) => contagem[faixa]); // Dados por faixa
            const totalPorFaixa = labels.map((faixa) => {
                const valores = Object.values(contagem[faixa]);
                return valores.reduce((sum, value) => sum + value, 0); // Total de pessoas na faixa etária
            });

            return { labels, data, totalPorFaixa };
        },

    },
    mounted() {
        this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
    }

}
</script>