<template>
    <v-card class="ma-0 pa-0" max-width="600" style="overflow-y: auto;"  fullscreen persistent>
        <v-card-title>
            PACIENTE: {{ currentItem.nome }} 
        </v-card-title>
        <v-card-text>
            <div id="imprimir">
                <v-card class="pa-2 ma-0 mt-4">
                    <v-row dense>
                        <v-col dense :cols="12">
                            <span class="text-caption">CONTATO:</span>  {{ currentItem.contato }}
                        </v-col>
                        <v-col dense :cols="12">
                            <span class="text-caption">Tempo de diabetes:</span>  {{ currentItem.tempo_diabetes }} - anos
                        </v-col>
                        <v-col dense :cols="12"><span class="text-caption">IDADE:</span> {{ calcularIdade(currentItem.dt_nascimento) }}</v-col>
                        <v-col dense :cols="6"><span class="text-caption">Glicemia Capilar:</span> {{ currentItem.glicemia_capilar }} mg/dl</v-col>
                        <v-col dense :cols="6"><span class="text-caption">Ref: Em jejum: 99 mg/dl <br>após alimentação: até 140 mg/dl</span></v-col>
                        <v-col dense :cols="6"><span class="text-caption">Glicemia Plasmática:</span> {{ currentItem.glicemia_plasmatica }} mg/dl</v-col>
                        <v-col dense :cols="6"><span class="text-caption">Ref: Em jejum: 70 a 99 mg/dl</span></v-col>
                        <v-col dense :cols="6"><span class="text-caption">Hemoglobina Glicada:</span> {{ currentItem.hemoglobina_glicada }}%</v-col>
                        <v-col dense :cols="6">
                            <span class="text-caption">
                                Ref: Em jejum: até 5,7% - Normal <br>de 5,8 a 6,4 - pré-diabético <br> de 6,5% - provável diabético
                            </span></v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD s/alteração:</span> {{ sim_nao(currentItem.od_sem_alteracao) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD rdnp leve:</span> {{ sim_nao(currentItem.od_rdnp_leve) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD rdnp moderada:</span> {{ sim_nao(currentItem.od_rdnp_moderada) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD rdnp grave/ m grave:</span> {{ sim_nao(currentItem.od_rdnp_grave_m_grave) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD rdp:</span> {{ sim_nao(currentItem.od_rdp) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OD edema macular:</span> {{ sim_nao(currentItem.od_edema_macular) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS sem alteracao:</span> {{ sim_nao(currentItem.os_sem_alteracao) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS rdnp leve:</span> {{ sim_nao(currentItem.os_rdnp_leve) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS rdnp moderada:</span> {{ sim_nao(currentItem.os_rdnp_moderada) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS rdnp grave/ m grave:</span> {{ sim_nao(currentItem.os_rdnp_grave_m_grave) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS rdp:</span> {{ sim_nao(currentItem.os_rdp) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">OS edema_macular:</span> {{ sim_nao(currentItem.os_edema_macular) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">catarata:</span> {{ sim_nao(currentItem.catarata) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">glaucoma:</span> {{ sim_nao(currentItem.glaucoma) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">outras:</span> {{ sim_nao(currentItem.outras) }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">observacoes doencas olhos:</span> {{ currentItem.observacoes_doencas_olhos }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">observacoes:</span> {{ currentItem.observacoes }}</v-col>
                        <v-col dense :cols="12"><span class="text-caption">orientar:</span> {{ currentItem.orientar }}</v-col>
                    </v-row>
                </v-card>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col dense :cols="12">
                    <v-btn :loading="loading" outlined block @click="imprimir()"><v-icon>mdi-printer</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <v-row min-height="20">
            <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
        </v-row>
    </v-card>
</template>
<script>
    import httpApiService from '@/http'
    import paciente from './Paciente/'
    export default{
        name:'Paciente',
        props:{},
        data:()=>({
            dialog:false,
            loading:false,
            msg:'',
            sexos:[],
            orgaos_expedidores:[],
            estados:[],
            frequencia_hemoglobina_glicada_diaria:[],
            cidades:[],
            problema_pes:[],
            currentItem:paciente
        }),
        methods:{
            imprimir() {
                const element = document.getElementById('imprimir');
                window.print(element);
            },
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            sim_nao(val) {
                if(val===null || val==='' ){
                    return 'N/A'
                }
                return val?'SIM':'NÃO';
            },
            get_listas(url, objeto){
                this.loading = true
                httpApiService.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            }, 
        },
        mounted(){
            this.get_listas(`mutirao-sus/paciente/${this.$route.params.id}/`, 'currentItem')
        },
       
    }
</script>
<style>

.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>