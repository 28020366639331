<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" class="ma-0 pa-0" max-width="600" style="overflow-y: auto;"  fullscreen persistent>

        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                class="custom-btn"
                block
                @click="start()"
            >   EXAME FUNDO DE OLHO
                <v-icon
                    small
                    class="ma-3"
                    color="white"
                    size="60"
                >
                    mdi-list-status
                </v-icon>
            </v-btn>
        </template>
        <v-card class="pa-0 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon class="mx-3" @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <LerQrcode @id_paciente="idPaciente"/> 
                </v-col>
            </v-row>
            <v-card-title class="ma-0 pa-0">
                
                <v-row dense>
                    <v-col class="mx-2">
                        <span class="ml-5">
                            EXAME FUNDO DE OLHO:
                        </span>
                        <span class="text-body" v-if="paciente.id">{{ paciente.id }}- {{ paciente.nome }} / {{ paciente.dt_nascimento }} / {{ paciente.cpf }}</span>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0 pa-0">
                <v-data-table 
                    :items="pacientes"
                    :headers="headers"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="true"
                    >  
                        <template v-slot:top>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                    v-model="search"
                                    label="Pesquisar"
                                    class="mx-2"
                                    clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn icon @click="atualiza()">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>    
                      
                        <template v-slot:item.chamar="{item}">
                            <ExameFundoOlho 
                                :open-modal="openDialog" 
                                :current-item="item" @fechadialog="fechaDailog" @atualiza="get_listas('mutirao-sus/pacientes/?etapa=biomarcador', 'pacientes')"/>
                        </template>    
                        
                        <template v-slot:item.dt_nascimento="{item}">
                            {{ calcularIdade(item.dt_nascimento) }}
                        </template>    
                           
                        <!--
                        -->
                        <!-- <template v-slot:item.editar="{item}">
                            <Sus :current-item="item"/>
                        </template>     -->
                    </v-data-table>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import api from '@/http'
    import LerQrcode from './LerQrcode.vue'
    export default{
        name:'ListaExameFundoOlho',
        components:{
            LerQrcode,
            ExameFundoOlho:()=>import('./ExameFundoOlho.vue'),
        },
        props:{
            paciente:{type:Object}
        },
        data:()=>({
            openDialog: false,
            dialog:false,
            loading:false,
            search:'',
            msg:'',
            pacientes:[],
            headers:[
                {text:'Prontuário',sorted:true,value:'id'},
                {text:'Nome',sorted:true,value:'nome'},
                {text:'Idade.',sorted:true,value:'dt_nascimento'},
                {text:'Chamar',sorted:true,value:'chamar'},
                // {text:'Editar',sorted:true,value:'editar'},
            ]
        }),
        methods:{
            idPaciente(val){
                this.busca(val)
            },
            fechaDailog(){
                this.openDialog = false
            },
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            get_listas(url, objeto){
                this.loading = true
                api.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
            start(){
                if(this.paciente.id>0){
                    this.search = this.paciente.id+''
                }
            },
            atualiza(){

                this.get_listas('mutirao-sus/pacientes/?etapa=exame-fundo-olho', 'pacientes')  
            },
            busca(id){
                
                this.get_listas(`mutirao-sus/pacientes/?etapa=paciente&id=${id}`, 'pacientes')  
                this.openDialog = true;
            }
        },
        mounted(){
            this.get_listas('mutirao-sus/pacientes/?etapa=exame-fundo-olho', 'pacientes')  
        }
    }
</script>
<style>

.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}
</style>