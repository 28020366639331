<template>
    <v-form ref="form">
        <v-dialog v-model="dialog" max-width="600" class="ma-0 pa-0" fullscreen persistent>
            <template v-slot:activator="{ on, attrs }">

                <v-btn v-bind="attrs" v-on="on" icon class="custom-btn">
                    <v-icon small class="ma-3" color="white">mdi-bullhorn-variant-outline</v-icon>
                </v-btn>
            </template>
            <v-card class="pa-0 ma-0">
                <v-row dense>
                    <v-col class="text-right">
                        <v-icon class="mx-3" @click="dialog = false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-card-title>
                    BIO MARCADOR / EXAME FUNDO DE OLHO
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <br><span class="h4 ml-2">Paciente: <b>{{ currentItem.nome }}</b></span>
                    <v-card class="pa-2 ma-0">
                        <v-row dense>
                            <v-col dense :cols="12" class="text-right ml-3">
                                <v-checkbox class="text-right text-azul" v-model="checkboxValuesOD.od_sem_alteracao"
                                    label="OD SEM ALTERAÇÃO" @change="handleSingleSelectionOD('od_sem_alteracao')">
                                    <template v-slot:label>
                                        <span class="azul">OD SEM ALTERAÇÃO</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOD.od_rdnp_leve"
                                    label="OD RDNP LEVE" @change="handleSingleSelectionOD('od_rdnp_leve')">
                                    <template v-slot:label>
                                        <span class="azul">OD RDNP LEVE</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOD.od_rdnp_moderada"
                                    label="OD RDNP MODERADO" @change="handleSingleSelectionOD('od_rdnp_moderada')">
                                    <template v-slot:label>
                                        <span class="azul">OD RDNP MODERADO</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOD.od_rdnp_grave_m_grave"
                                    label="OD RDNP GRAVE" @change="handleSingleSelectionOD('od_rdnp_grave_m_grave')">
                                    <template v-slot:label>
                                        <span class="azul">OD RDNP GRAVE</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOD.od_rdp" label="OD RDP"
                                    @change="handleSingleSelectionOD('od_rdp')">
                                    <template v-slot:label>
                                        <span class="azul">OD RD PROLIFERATIVA</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOD.od_edema_macular"
                                    label="OD EDEMA MACULAR" @change="handleSingleSelectionOD('od_edema_macular')">
                                    <template v-slot:label>
                                        <span class="azul">OD EDEMA MACULAR</span>
                                    </template>
                                </v-checkbox>
                            </v-col>


                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider class="ma-1"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col dense :cols="12" class="text-right ml-3">
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_sem_alteracao"
                                    label="OD SEM ALTERAÇÃO" @change="handleSingleSelectionOE('oe_sem_alteracao')">
                                    <template v-slot:label>
                                        <span class="roxo">OE SEM ALTERAÇÃO</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_rdnp_leve"
                                    label="OE RDNP LEVE" @change="handleSingleSelectionOE('oe_rdnp_leve')">
                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP LEVE</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_rdnp_moderada"
                                    label="OE RDNP MODERADO" @change="handleSingleSelectionOE('oe_rdnp_moderada')">
                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP MODERADO</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_rdnp_grave_m_grave"
                                    label="OE RDNP GRAVE" @change="handleSingleSelectionOE('oe_rdnp_grave_m_grave')">
                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP GRAVE</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_rdp" label="OE RDP"
                                    @change="handleSingleSelectionOE('oe_rdp')">
                                    <template v-slot:label>
                                        <span class="roxo">OE RD PROLIFERATIVA</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="checkboxValuesOE.oe_edema_macular"
                                    label="OE EDEMA MACULAR" @change="handleSingleSelectionOE('oe_edema_macular')">
                                    <template v-slot:label>
                                        <span class="roxo">OE EDEMA MACULAR</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider class="ma-1"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="ml-3">
                                <v-checkbox class="text-right" v-model="currentItem.catarata">
                                    <template v-slot:label>
                                        <span class="verde">CATARATA</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox class="text-right" v-model="currentItem.glaucoma">
                                    <template v-slot:label>
                                        <span class="verde">GLAUCOMA</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-divider class="ma-1"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="text-h6 text-center">CONDUTA</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="ml-3 text-start">
                                
                                <v-radio-group
                                    v-model="currentItem.orientar"
                                    column
                                    :mandatory="true"
                                    class="pa-3"
                                >
                                <v-radio label="ALTA" value="ALTA" />
                                <v-radio label="REGULAR CONSULTA" value="CONSULTA" />
                                <v-radio label="REGULAR CIRURGIA/LASER" value="CIRURGIA/LASER" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col dense :cols="12">
                            <v-btn :loading="loading" outlined block
                                @click="post_lista()"><v-icon>mdi-content-save</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-row min-height="20">
                    <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import httpApiService from '@/http'
export default {
    name: 'ExameFundoOlho',
    props: {
        currentItem: {
            type: Object
        },
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        msg: '',
        sexos: [],
        orgaos_expedidores: [],
        estados: [],
        frequencia_hemoglobina_glicada_diaria: [],
        cidades: [],
        problema_pes: [],
        checkboxValuesOD: {
            od_sem_alteracao: false,
            od_rdnp_leve: false,
            od_rdnp_moderada: false,
            od_rdnp_grave_m_grave: false,
            od_rdp: false,
            od_edema_macular: false,
        },
        checkboxValuesOE: {
            oe_sem_alteracao: false,
            oe_rdnp_leve: false,
            oe_rdnp_moderada: false,
            oe_rdnp_grave_m_grave: false,
            oe_rdp: false,
            oe_edema_macular: false,
        },

    }),
    methods: {
        get_listas(url, objeto) {
            this.loading = true
            httpApiService.get(url)
                .then(res => {
                    this[objeto] = res.data
                })
                .finally(() => { this.loading = false })
        },

        async post_lista() {
            this.loading = true
            if (!this.$refs.form.validate()) {
                this.loading = false
                return false
            }
            const curr = {
                id: this.currentItem.id,
                catarata: this.currentItem.catarata,
                glaucoma: this.currentItem.glaucoma,
                orientar: this.currentItem.orientar
            };          
            const combinedCheckboxValues =  { 
                ...curr, 
                ...this.checkboxValuesOD, 
                ...this.checkboxValuesOE 
            };

            const response = await httpApiService.patch(`mutirao-sus/paciente/${combinedCheckboxValues.id}/`, combinedCheckboxValues)

            if (response.status === 200) {
                this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                //this.$emit('atualiza')
                this.$emit('fechadialog', true)
            } else {
                for (const i in response.response.data) {
                    this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                }
            }
            this.loading = false

        },
        handleSingleSelectionOD(selectedKey) {
            // Desmarca todos os checkboxes, exceto o selecionado
            /*Object.keys(this.checkboxValuesOD).forEach((key) => {
                if (key !== selectedKey) {
                this.checkboxValuesOD[key] = false;
                }
            });*/
            console.log(selectedKey)
        },
        handleSingleSelectionOE(selectedKey) {
            // Desmarca todos os checkboxes, exceto o selecionado
            /*Object.keys(this.checkboxValuesOE).forEach((key) => {
                if (key !== selectedKey) {
                this.checkboxValuesOE[key] = false;
                }
            });*/
            console.log(selectedKey)
        },
    },
    mounted() {
        this.checkboxValuesOD.od_edema_macular = this.currentItem.od_edema_macular
        this.checkboxValuesOD.od_rdnp_leve = this.currentItem.od_rdnp_leve
        this.checkboxValuesOD.od_rdnp_moderada = this.currentItem.od_rdnp_moderada
        this.checkboxValuesOD.od_rdnp_grave_m_grave = this.currentItem.od_rdnp_grave_m_grave
        this.checkboxValuesOD.od_rdp = this.currentItem.od_rdp
        this.checkboxValuesOD.od_sem_alteracao = this.currentItem.od_sem_alteracao

        this.checkboxValuesOE.oe_edema_macular = this.currentItem.oe_edema_macular
        this.checkboxValuesOE.oe_rdnp_leve = this.currentItem.oe_rdnp_leve
        this.checkboxValuesOE.oe_rdnp_moderada = this.currentItem.oe_rdnp_moderada
        this.checkboxValuesOE.oe_rdnp_grave_m_grave = this.currentItem.oe_rdnp_grave_m_grave
        this.checkboxValuesOE.oe_rdp = this.currentItem.oe_rdp
        this.checkboxValuesOE.oe_sem_alteracao = this.currentItem.oe_sem_alteracao
    },
    watch: {
        openModal: {
            immediate: true,
            handler(newVal) {
                this.dialog = newVal; // Sincroniza a abertura do modal
            },
        },
    },

}
</script>
<style>
.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important;
    /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important;
    /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}

.azul {
    color: rgb(31, 49, 128)
}

.roxo {
    color: rgb(148, 3, 141)
}

.verde {
    color: rgb(10, 112, 32)
}

.cinza {
    color: rgb(16, 17, 16)
}
</style>