<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" class="ma-0 pa-0" max-width="600" style="overflow-y: auto;"  fullscreen persistent>
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                class="custom-btn"
                block
                @click="start()"
            >   Pacientes {{ pacientes.length }}
                <v-icon
                    small
                    class="ma-3"
                    color="white"
                    size="60"
                >
                    mdi-account-heart
                </v-icon>
                
            </v-btn>
        </template>
        <v-card class="pa-0 ma-0">
            <v-row>
                <v-col>
                    <LerQrcode @id_paciente="idPaciente"/> 
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title class="pa-0 ma-0">
                <v-row dense>
                    <v-col class="mx-2">
                        <span class="ml-5">
                            Pacientes 
                        </span>
                        <span class="text-body" v-if="paciente.id">{{ paciente.id }}- {{ paciente.nome }} / {{ paciente.dt_nascimento }} / {{ paciente.cpf }}</span>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pa-0 ma-0">
                <v-data-table 
                    :items="filtro"
                    :headers="headers"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="true"
                    >  
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="search"
                                    label="Pesquisar"
                                    class="mx-4"
                                    clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <v-radio-group row label="Regulados" 
                                        v-model="orientacao" @change="filtrar()">
                                        <v-radio value="CONSULTA" label="CONSULTA"></v-radio> 
                                        <v-radio value="CIRURGIA" label="CIRURGIA"></v-radio> 
                                        <v-radio value="LASER" label="LASER"></v-radio> 
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn @click="refresh()" outlined><v-icon>mdi-refresh</v-icon></v-btn> 
                                </v-col>
                            </v-row>
                        </template>    
                    
                        <template v-slot:item.dt_nascimento="{item}">
                            {{ calcularIdade(item.dt_nascimento) }}
                        </template> 
                        <template v-slot:item.orientar="{item}">
                            {{ sim_nao(item.orientar) }}
                        </template>

                        <!-- <template v-slot:item.chamar="{item}">
                            <Chamar :current-item="item.id"/>
                        </template>     -->
                      
                        <template v-slot:item.ver="{item}">
                            <!-- <Paciente :current-item="item"/> -->
                            <router-link :to="`mutirao-sus-printer/${item.id}`" target="_blank"><v-icon>mdi-printer</v-icon></router-link>
                        </template> 
                        <template v-slot:item.qr="{item}">
                            
                            <QRCode :textoqrc="res(item)" :paciente-label="`${item.nome} ${new Date(item.dt_nascimento+' 00:00:00').toLocaleDateString()}`"></QRCode>
                        </template>    
                    </v-data-table>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import api from '@/http'
    import LerQrcode from './LerQrcode.vue'
    import QRCode from './QRcode.vue'
    export default{
        name:'Lista',
        components:{
            LerQrcode,
            QRCode,
            // Chamar:()=>import('./Chamar.vue'),
            // Paciente:()=>import('./Paciente.vue'),
        },
        props:{
            paciente:{type:Object}
        },
        data:()=>({
            dialog:false,
            loading:false,
            search:'',
            msg:'',
            pacientes:[],
            filtro:[],
            orientacao:null,
            headers:[
                {text:'Prontuário',sorted:true,value:'id'},
                {text:'Nome',sorted:true,value:'nome'},
                {text:'Cpf',sorted:true,value:'cpf'},
                {text:'Data de Nasc.',sorted:true,value:'dt_nascimento'},
                {text:'Regulados',sorted:true,value:'orientar'},
                {text:'Ver',sorted:true,value:'ver'},
                {text:'qr',sorted:true,value:'qr'},
            ]
        }),
        methods:{
            idPaciente(val){
                console.log('id_paciente',val)
                this.busca(val)
            },
            refresh(){
                this.filtro = this.pacientes
                this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
            },
            calcularIdade(anoNascimento) {
                const anoAtual = new Date().getFullYear();
                return anoAtual - new Date(anoNascimento).getFullYear();
            },
            get_listas(url, objeto){
                this.loading = true
                api.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
            sim_nao(val) {
                if(val===null || val==='' ){
                    return 'N/A'
                }
                return val?'SIM':'NÃO';
            },
            filtrar(){
               this.filtro = this.pacientes.filter(e=>{
                    if(e.orientar===this.orientacao){
                        return e
                    }else if(this.orientacao === 2){
                        return e
                    }
                })
            },
            start(){
                if(this.paciente.id>0){
                    this.search = this.paciente.id+''
                }
            },
            atualiza(){

                this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')  
            },
            busca(id){
                
                this.get_listas(`mutirao-sus/pacientes/?etapa=paciente&id=${id}`, 'pacientes')  
            },
            res(val){
               const url_ = process.env.NODE_ENV !== 'production'?'http://localhost:8080/':'https://servicos.hro.med.br/';
                     return`${url_}paciente-sus/${val.id}`
                  
            }
        },
        mounted(){
            this.get_listas('mutirao-sus/pacientes/?etapa=todos', 'pacientes')
        }
    }
</script>
<style>

.custom-card {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}
</style>