var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-map',{staticStyle:{"width":"100%","height":"600px"},attrs:{"zoom":14,"center":_vm.center,"options":{
        zoomControl: true,
        mapTypeControl: false,
        backgroundColor:'#000000',
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: {
          default: [],
          hide: [
            {
              featureType: 'poi.attraction',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }, 
        disableDefaultUi: false
      }}},[_vm._l((_vm.locationMarkers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"title":m.title},on:{"click":function($event){return _vm.toggleInfoWindow(m,index)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_vm._v(" "+_vm._s(_vm.infoContent)+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }