<template>
    <div>
        <Bar :chart-data="chartData" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
            :styles="styles" :width="width" :height="height" :chart-options="chartOptions" />

    </div>

</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {

    name: 'GraficoSemana',
    components: {
        Bar
    },
    props: {
        base: {
            type: Object
        },
        label: {
            type: String,
            required: false,
            default: 'Tudo'
        },
        chartId: {
            type: String,
            default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 40
        },
        height: {
            type: Number,
            default: 10
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        semana: null,
    }),

    computed: {
        chartData() {
            const { labels, data } = this.base;

            let datasets = [
                {
                    label: this.label,
                    backgroundColor: ['#41B883', '#E46651', '#00BFFF', '#ADFF2F', '#008080', '#b98bd8'],
                    data: data,
                },
            ];

            return {
                labels: labels,
                datasets: datasets,
            };
        },
        chartOptions() {
            return {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: `Distribuição por ${this.label} (com Percentuais)`,
                        font: {
                            size: 18,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const total = context.dataset.data.reduce((sum, value) => sum + value, 0);
                                const value = context.raw; // Valor da barra
                                const percentual = ((value / total) * 100).toFixed(2); // Percentual
                                return `${context.label}: ${value} (${percentual}%)`;
                            },
                        },
                    },
                    datalabels: {
                        color: '#000', // Cor do texto
                        anchor: 'end', // Posição do rótulo (topo, meio, etc.)
                        align: 'end', // Alinhamento (esquerda, centro, direita)
                        formatter: (value, context) => {
                            // Formatar o texto a ser exibido
                            const total = context.dataset.data.reduce((sum, v) => sum + v, 0);
                            const percentual = ((value / total) * 100).toFixed(2);
                            return `${value} (${percentual}%)`;
                        },
                        font: {
                            size: 12, // Tamanho da fonte
                        },
                    },
                },
            };
        }

    },
}


</script>