import axios from 'axios'
export const isHomolog = true

function getHosts() {
  if (process.env.NODE_ENV !== 'production') {
    return ['https://apps.hro.med.br', 'http://localhost:8080']
  }
  return isHomolog ?
  ['https://apps.hro.med.br', 'http://servicos.hro.med.br'] :
  ['https://apps.hro.med.br', 'http://intra.hro.int']
}

export const [apiHost, urlHost] = getHosts()
const httpApiService = axios.create({
  baseURL: apiHost,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('tokenAccess')}`,
    'Accept': 'application/json',
  }
})

httpApiService.interceptors.response.use( 
  response => response,
  error => {
    if (error.response){
      if (error.response.status === 401 && location.pathname !== '/') {
        localStorage.removeItem('tokenAccess')
        location.pathname !== '/login' ? location = `${urlHost}/login` : null
      }
    }
    return error
  }
)

export default httpApiService