<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6" class="text-center">
        <v-btn @click="generateQRCode" color="primary">Gerar QR Code</v-btn>
        <div v-if="qrCode">
          <div id="qrcode1">
            <img :src="qrCode" alt="QR Code" />
            <p class="text-caption">{{ pacienteLabel }}</p>
          </div>
          <v-btn @click="printQRCode" color="secondary" class="ml-2">
            Imprimir QR Code
          </v-btn>
        </div>
        <p v-if="error" class="error-message">{{ error }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QRCode from "qrcode";
// import html2canvas from "html2canvas";

export default {
  props: {
    textoqrc: {
      type: String,
      default: "",
    },
    pacienteLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      qrCode: null,
      error: "",
    };
  },
  methods: {
    async generateQRCode() {
      if (!this.textoqrc.trim()) {
        this.error = "Por favor, insira um ID válido.";
        return;
      }

      try {
        this.qrCode = await QRCode.toDataURL(this.textoqrc, {
          width: 250, // Tamanho fixo para manter o formato quadrado
          margin: 2, // Margem no QR Code
        });
        this.error = "";
      } catch (error) {
        console.error("Erro ao gerar QR Code:", error);
        this.error = "Ocorreu um erro ao gerar o QR Code.";
      }
    },
    async printQRCode() {
      if (!this.qrCode) return;
      try {
        const pac = this.pacienteLabel;
        const dataURL = this.qrCode;

        // Criar nova janela para impressão
        const printWindow = window.open("", "_blank");
        const printContent = `
          <html>
          <head>
            <title>Impressão QR Code</title>
            <style>
              body {
                width: 100mm;
                height: 50mm;
                margin: 0;
                font-family: Arial, sans-serif;
                position: relative;
              }
              .container {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 3mm;
                box-sizing: border-box;
              }
              .qrcode {
                flex: 0 0 50mm;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .qrcode img {
                width: 48mm;
                height: 48mm;
                margin: 2mm;
              }
              .info {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: 5mm;
              }
              .info span {
                font-size: 14px;
                font-weight: bold;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <div class="qrcode">
                <img src="${dataURL}" alt="QR Code" title="${pac}" />
              </div>
              <div class="info">
                <span>${pac}</span>
              </div>
            </div>
          </body>
          </html>
        `;
        this.qrCode = null;
        printWindow.document.write(printContent);
        printWindow.document.close();

        // Imprimir após carregar o conteúdo
        printWindow.onload = () => {
          printWindow.print();
          printWindow.close();
        };
      } catch (error) {
        console.error("Erro ao imprimir QR Code:", error);
        this.error = "Ocorreu um erro ao imprimir o QR Code.";
      }
    },
  },
};
</script>

<style scoped>
img {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
}
.error-message {
  color: red;
  margin-top: 10px;
}

</style>
