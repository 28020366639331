<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" class="ma-0 pa-0" max-width="600" fullscreen persistent>
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-if="currentItem.id===null" 
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                block
                class="custom-btn"
                @click="loadObject()"
            >   Cadastro
                <v-icon small class="ma-3" color="white">mdi-plus-outline</v-icon>
            </v-btn>
            
            <v-btn
                v-else 
                v-bind="attrs"
                v-on="on"
                icon
                class="custom-btn"
                @click="loadObject()"
            > 
                <v-icon small class="ma-3" color="white">mdi-account-edit</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-0 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon class="mx-3" @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-text class="ma-0 pa-0">
                <v-card v-show="show_opcoes_cadastro" class="pa-2 ma-2">
                    <v-card-title>IDENTIFICAÇÃO DO PACIENTE</v-card-title>
                    <v-row dense>
                        <v-col dense :cols="12">
                            <v-text-field 
                            dense required :key="formKey" 
                            :rules="[e=>!!e||'Campo obrigatório!']" 
                            v-model="paciente.nome" label="Paciente"
                             @input="paciente.nome = paciente.nome.toUpperCase()"></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field dense required :key="formKey+1" :rules="[e=>!!e||'Campo obrigatório!']" v-model="paciente.dt_nascimento" type="date" label="Data nascimento"></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field 
                                dense 
                                required 
                                :key="formKey+1" 
                                v-model="paciente.contato" 
                                v-mask="'(##) #####.####'" 
                                label="Celular">
                            </v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-autocomplete 
                                :items="sexos"
                                dense required 
                                :key="formKey+1" 
                                :rules="[e=>!!e||'Campo obrigatório!']" 
                                v-model="paciente.sexo" 
                                label="Sexo"
                            ></v-autocomplete>
                        </v-col>
                        <!--
                        <v-col dense :cols="12">
                            <v-text-field 
                            dense 
                            required 
                            :key="formKey+1" 
                            :rules="[e=>!!e||'Campo obrigatório!']" 
                            v-model="paciente.cpf" 
                            label="CPF"
                            v-mask="'###.###.###-##'"
                            ></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field dense :key="formKey+1" v-model="paciente.email" label="E-mail"></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field type="number" 
                            @keypress="preventNegative" dense required :key="formKey+1" :rules="[e=>!!e||'Campo obrigatório!']" v-model="paciente.tempo_diabetes" label="Tempo de diabetes em anos"></v-text-field>
                        </v-col>-->
                    </v-row>
                </v-card>
            </v-card-text>
            <v-card-text v-show="!show_opcoes_cadastro">
                <QRcode :textoqrc="response" :paciente-label="paciente_label"></QRcode>
            </v-card-text>
            <v-card-actions>
                <v-row dense>
                    <v-col dense :cols="12">
                        <v-btn :loading="loading" v-show="show_opcoes_cadastro" :disabled="!disabled_btn_save" outlined block @click="post_lista()">salvar<v-icon>mdi-content-save</v-icon></v-btn>
                        <v-btn :loading="loading" v-show="!show_opcoes_cadastro" outlined block @click="novo_cadastro()">novo cadastro<v-icon>mdi-skip-previous-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-row min-height="20">
                <v-col dense :cols="12" class="text-center">
                    <span v-html="msg"></span>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import httpApiService from '@/http'
    import paciente from "./Paciente/index";
    export default{
        name:'Sus',
        components:{
            QRcode:()=>import('./QRcode.vue')
        },
        props:{currentItem:{type:Object}},
        data:()=>({
            dialog:false,
            loading:false,
            formKey: 0,
            show_opcoes_cadastro:true,
            msg:'',
            sexos:['Masculino', 'Feminino', 'Intersexo', 'Não especificado', 'Outro'],
            orgaos_expedidores:[],
            paciente:paciente,
            estados:[],
            frequencia_hemoglobina_glicada_diaria:[],
            cidades:[],
            problema_pes:[],
            cpf:'',
            response:'',
            paciente_label: ''
        }),
        methods:{
            preventNegative() {
                if (this.paciente.tempo_diabetes < 0) {
                    this.paciente.tempo_diabetes = 0; // Reseta para zero se o valor for negativo
                }
            },
            get_listas(url, objeto){
                this.loading = true
                httpApiService.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
            validateEmail() {
                // Expressão regular simples para validar e-mail
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                // Atualizar a flag de validação com base na expressão regular
                this.emailValid = emailRegex.test(this.email);
            },
            async post_lista(){
                this.loading = true
                if(!this.$refs.form.validate()) {
                    this.loading=false 
                    return false
                }
                var paciente = this.paciente
                paciente.nome = this.paciente.nome.toUpperCase()
                // paciente.cpf = this.paciente.cpf.replaceAll('.', '').replaceAll('-', '');
                paciente.telefone = this.paciente.pais+this.paciente.nr_telefone
                const sexoMap = {       
                    'Masculino': 'M',
                    'Feminino': 'F',
                    'Intersexo': 'I',
                    'Não especificado': 'N',
                    'Outro': 'O'
                };

                // Atribuir a abreviação correspondente
                paciente.sexo = sexoMap[this.paciente.sexo] || '';

                const response = await httpApiService.post('mutirao-sus/pacientes/',paciente)
                if(response.status===201){
                    const url_ = process.env.NODE_ENV !== 'production'?'http://localhost:8080/':'https://servicos.hro.med.br/';
                    this.response = `${url_}paciente-sus/${response.data.id}`
                    this.paciente_label = `${paciente.nome} ${new Date(paciente.dt_nascimento+' 00:00:00').toLocaleDateString()}`
                    console.log(this.paciente_label)
                    this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                    this.limpa_campos()
                    this.$emit('atualizaPlus', true)
                    this.get_listas()
                    this.formKey += 1;
                }else{
                    for(const i in response.response.data){
                        this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                    }    
                }
                this.loading=false
                this.show_opcoes_cadastro = false
                
            },
            limpa_campos(){
                for (const chave in this.paciente) {
                    this.paciente[chave] = null; 
                }
            },
            loadObject(){
                if(this.currentItem.id!==null){
                    this.paciente = this.currentItem
                }
            },
            novo_cadastro(){
                this.show_opcoes_cadastro = true
                this.response = ''
                this.paciente_label = ''
            }
        },
        computed: {
            disabled_btn_save(){
                let nome = paciente.nome!==null ? paciente.nome.length>0:false
                let dt_nascimento = paciente.dt_nascimento!==null ? paciente.dt_nascimento.length>0:false
                let contato = paciente.contato!==null ? paciente.contato.length>0:false
                //let tempo_diabetes = paciente.tempo_diabetes!==null ? paciente.tempo_diabetes.length>0:false
                return  nome&&dt_nascimento&&contato         
            }
        }
    }
</script>
<style>

.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>