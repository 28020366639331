import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('./components/Login.vue'),
      name: "login",
      meta: {
        title: "HRO | login",
        requirestAuth: false
      }
    },
    
    {
      path: '/inventario',
      component: () => import('./components/inventory/Inventory.vue'),
      name: "Inventario",
      meta: {
        title: "HRO | Inventario",
        requirestAuth: false
      }
    },
    
   // inventory
    {
      path: '/logout',
      component: () => import('./components/Logout.vue'),
      name: "logout",
      meta: {
        title: "HRO | logout",

      }
    },
    {
      path: '/mutirao-sus',
      component: () => import('./components/sus/Menu.vue'),
      name: "mutirao-sus",
      meta: {
        title: "HRO | Sus",
      }
    },
    {
      path: '/paciente-sus/:id',
      component: () => import('./components/sus/Menu.vue'),
      name: "paciente-sus",
      meta: {
        title: "HRO | Sus",
      }
    },
    {
      path: '/dashboard-sus',
      component: () => import('./components/sus/Dashboard.vue'),
      name: "dashboard-sus",
      meta: {
        title: "HRO | Sus",
      }
    },
    {
      path: '/mutirao-sus-printer/:id',
      component: () => import('./components/sus/Paciente.vue'),
      name: "mutirao-sus-printer",
      meta: {
        title: "HRO | Sus",
      }
    },
    {
      path: '/atendimentos-paciente',
      component: () => import('./components/atendimentos/AtendimentosPaciente.vue'),
      name: "atendimentos-paciente",
      meta: {
        title: "HRO | logout",

      }
    },
    {
      path: '/faq',
      component: () => import('./components/Faq.vue'),
      name: "faq",
      meta: {
        title: "HRO | FAQ",

      }
    },
    {
      path: '/tasy',
      component: () => import('./components/prontuario/Pdf.vue'),
      name: "tasy",
      meta: {
        title: "HRO | logout",

      }
    },
    {
      path: '/kpis',
      component: () => import('./etl/views/Kpis.vue'),
      name: "kpis",
      meta: {
        title: "HRO | kpis",

      }
    },
    {
      path: '/historico',
      component: () => import('./etl/views/Historico.vue'),
      name: "historico",
      meta: {
        title: "HRO | kpis",

      }
    },
    {
      path: '/',
      alias: '/home',
      component: () => import('./components/home/Home.vue'),
      name: "home",
      meta: {
        title: "HRO | Home",
      },
      redirect: { name: 'apps_menu' }
    },
    {
      path: '/aplicativos',
      component: () => import('./components/Menu.vue'),
      name: "apps_menu",
      meta: {
        title: "HRO | Aplicativos",
        requirestAuth: true
      }
    },
    {
      path: '/apps',
      name: "apps",
      redirect: { name: 'apps_menu' }
    },
    {
      path: '/filas',
      component: () => import('./etl/views/Fila.vue'),
      name: "filas",
      meta: {
        title: "HRO | Filas",
        requirestAuth: true
      }
    },
    {
      path: '/mapa',
      component: () => import('./components/mapa/Mapa.vue'),
      name: "mapa",
      meta: {
        title: "HRO | Mapas",
        requirestAuth: true
      }
    },
    {
      path: '/agendamentos',
      component: () => import('./etl/views/Agendamentos.vue'),
      name: "agendamentos",
      meta: {
        title: "HRO | Agendamentos",
        requirestAuth: true
      }
    },    
    {
      path: '/pacientes',
      component: () => import('./etl/views/Pacientes.vue'),
      name: "pacientes",
      meta: {
        title: "HRO | Pacientes",
        requirestAuth: true
      }
    },
    {
      path: '/informe',
      component: () => import('./components/informe-transforme/Informe.vue'),
      name: "informe",
      
      meta: {
        title: "HRO | Informe",
        requirestAuth: true
      }
    },
    {
      path: '/exames',
      component: () => import('../src/etl/components/AgendarExame.vue'),
      name: "Exames",
      meta: {
        title: "HRO | Exames",
        requirestAuth: true
      }
    },
    { 
      path: '/sugestoes',
        component: () => import('./components/informe-transforme/sugestoes/Sugestoes.vue'),
        name: 'sugestoes',
        meta: {
          title: "HRO | Sugestões", requirestAuth: true
        },
    },
    {
      path: '/ouvidoria',
      component: () => import('./components/informe-transforme/ouvidoria/Ouvidoria.vue'),
      name: 'ouvidoria',
      meta: {
        title: "HRO | Ouvidoria", requirestAuth: true
      }
    },
    {
      path: '/chat',
      component: () => import('./chat/Chat.vue'),
      name: "chat",
      meta: {
        title: "HRO | chat",
        requirestAuth: true
      }
    },
    {
      path: '/pessoas',
      component: () => import('./components/pessoas/Pessoas.vue'),
      name: "pessoas",
      meta: {
        title: "HRO | Pessoas",
        requirestAuth: true
      }
    },
    {
      path: '/ramais',
      component: () => import('./components/ramais/Ramais.vue'),
      name: "ramais",
      meta: {
        title: "HRO | Ramais",
        requirestAuth: true
      }
    },
    {
      path: '/contas',
      component: () => import('./components/contas/Contas.vue'),
      name: 'contas',
      children: [{
        path: 'troca-senha',
        component: () => import('./components/contas/TrocaSenha.vue'),
        name: 'troca-senha',
        meta: {
          title: "HRO | Trocar minha senha de usuario", requirestAuth: true
        },
      }, {
        path: 'edit',
        component: () => import('./components/contas/Edit.vue'),
        name: 'edite-usuario',
        meta: {
          title: "HRO | Edite Usuario", requirestAuth: true
        },
      }],
      meta: {
        title: "HRO | Meu usuario", requirestAuth: true
      },
    },
    {
      path: '/controle-temperatura',
      component: () => import('./components/controleTemperatura/ControleTemperatura.vue'),
      name: "contr_temperatura",
      meta: {
        title: "HRO",
        requirestAuth: true
      }
    },
    {
      path: '/mensagens',
      component: () => import('./components/mensagens/Mensagens.vue'),
      name: "mensagens",
      meta: {
        title: "HRO | mensagens",
        requirestAuth: true
      }
    },
    {
      path: '/exames_imagens',
      component: () => import('./components/exames_imagens/Lista.vue'),
      name: "exames_imagens",
      meta: {
        title: "HRO | Exames de imagens",
        requirestAuth: true
      }
    },
    {
      path: '/protuario_tasy',
      component: () => import('./components/exames_imagens/Tasy.vue'),
      name: "protuario_tasy",
      meta: {
        title: "HRO | Prontuários do Tasy",
        requirestAuth: true
      }
    },
    {
      path: '/agendar-exame',
      component: () => import('./etl/components/AgendarExame.vue'),
      name: "agendamento-exame",
      meta: {
        title: "HRO | Agendamento de exames",
        requirestAuth: false
      }
    },
    {
      path: '/painel',
      component: () => import('./components/painel/Lista.vue'),
      name: "painel",
      meta: {
        title: "HRO | Painel de senhas",
        requirestAuth: false
      }
    },
    {
      path: '/leitor-texto',
      component: () => import('./components/painel/LeitoTexto.vue'),
      name: "leitor_texto", 
      meta: {
        title: "HRO | Painel de senhas",
        requirestAuth: true
      }
    },
    /*{
      path: '/totem',
      component: () => import('./components/totem/Totem.vue'),
      name: "totem",
      meta: {
        title: "HRO | Totem de senhas",
      }
    },*/
    {
      path: '/indicadores',
      component: () => import('./components/indicadores/Home.vue'),
      name: "home_indicadores",
      children: [{
        path: 'lista',
        component: () => import('./components/indicadores/Lista.vue'),
        name: 'indicadores',
        meta: {
          title: "HRO | Avaliação de indicadores",
          requirestAuth: true
        },
      }, {
        path: 'avaliador',
        component: () => import('./components/indicadores/Avaliador.vue'),
        name: 'avaliador_indicadores',
        meta: {
          title: "HRO | Avaliação de indicadores",
          requirestAuth: true
        },
      }, {
        path: 'meus-indicadores',
        component: () => import('./components/indicadores/Medico.vue'),
        name: 'meus_indicadores',
        meta: {
          title: "HRO | Meus indicadores",
          requirestAuth: true
        },
      }],
      meta: {
        title: "HRO | Indicadores",
        requirestAuth: true
      },
    },
    { path: '*', redirect: '/informe' },
  ]
});

router.beforeEach((to, from, next) => {
  let usertoken = localStorage.getItem('tokenAccess')
  if (to.meta.requirestAuth) {
    if (usertoken) {
      document.title = to.meta.title
      next()
    } else {
      document.title = to.meta.title
      next({ name: 'login' })
    }
  } else if (to.name === 'login' && usertoken){
    next({name: 'apps'})
  }
  next();
});

export { router };
